import React from "react";

type TextInputProps = {
    type: string;
    label: string;
    placeholder : string;
};

function TextInput({ type, label, placeholder }: TextInputProps)
{
    return (
        <>
            <div className="relative">
                <input 
                    type={ type } 
                    id="outlined_error" 
                    aria-describedby="outlined_error_help" 
                    className="block px-2.5 pb-2.5 pt-4 w-full border-blue-700 text-sm text-blue-900 bg-transparent rounded-lg 
                                border-1 appearance-none dark:text-white dark:border-blue-600 border-blue-600 peer
                                dark:focus:border-gray-500 focus:ring-0 focus:border-blue-400" 
                    placeholder={ placeholder } 
                />
                <label 
                    htmlFor="outlined_error" 
                    className="absolute text-sm text-blue-600 dark:text-red-500 duration-300 transform 
                                -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
                                peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4
                                rtl:peer-focus:left-auto"
                >{ label }</label>
            </div>
        {/* <p id="outlined_error_help" className="mt-2 text-xs text-red-600 dark:text-red-400"><span className="font-medium">Oh, snapp!</span> Some error message.</p>        */}
        </>
    )
}


export default TextInput