import React from "react";
import Logo from '../../logo.png';
import { Link } from 'react-router-dom';
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

function Login()
{

    return (
        <>
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 md:mt-18">
                <br /><br /><br />
                <div className="sm:mx-auto sm:w-full sm:max-w-sm md:mt-20">
                    <img className="mx-auto h-20 w-20" src={ Logo } alt="Your Company" />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Account Login</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-2" action="/dashboard" method="get">
                    <div>
                        <TextInput
                            type = 'email'
                            label = 'Email Address'
                            placeholder = 'Email Address'
                        />
                    </div>
                    <div>
                        <div className="text-right text-sm mb-2">
                            <Link to='/forget-password' className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</Link>
                        </div>
                        <TextInput
                            type = 'password'
                            label = 'Password'
                            placeholder = 'Password'
                        />
                    </div>

                    <div>
                        <Button
                            text = "Login"
                        />
                    </div>
                    </form>

                    <p className="mt-4 text-center text-sm text-gray-500">
                    Not a member?
                    <Link to='/register' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Create an account</Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Login