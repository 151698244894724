import React from "react";
import Logo from '../../logo.png';
import { Link } from 'react-router-dom';
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

function Register()
{
    return (
        <>
            <div className="flex min-h-full flex-col justify-center px-5 py-10 lg:px-8 md:mt-15">
                <br /><br />
                <div className="sm:mx-auto sm:w-full sm:max-w-sm md:mt-16">
                    <img className="mx-auto h-20 w-20" src={ Logo } alt="Your Company" />
                    <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create an account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-3" action="#" method="POST">
                    <div>
                        <TextInput
                            type = "email"
                            label = "Email Address"
                            placeholder = "Email Address"
                        />
                    </div>
                    <div>
                        <TextInput
                            type = "password"
                            label = "Password"
                            placeholder = "Password"
                        />
                    </div>
                    <div>
                        <TextInput
                            type = "password"
                            label = "Confirm Password"
                            placeholder = "Confirm Password"
                        />
                    </div>

                    <div>
                        <Button
                            text = "Register"
                        />
                    </div>
                    </form>

                    <p className="mt-4 text-center text-sm text-gray-500">
                    Already have an account?
                    <Link to='/login' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> LogIn</Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Register