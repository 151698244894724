import React from "react";
import TextInput from "../../components/TextInput";
import Logo from '../../logo.png';
import { Link } from "react-router-dom";
import Button from "../../components/Button";

function ForgetPassword()
{
    return (
        <>
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 md:mt-18">
                <br /><br />
                <div className="sm:mx-auto sm:w-full sm:max-w-sm md:mt-20">
                    <img className="mx-auto h-20 w-20" src={ Logo } alt="Your Company" />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot Password</h2>
                    <small>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate veniam hic amet ipsa inventore explicabo 
                        impedit! Quam aperiam, mollitia.
                    </small>
                </div>

                <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-2" action="#" method="POST">
                    <div>
                        <TextInput
                            type = 'email'
                            label = 'Email Address'
                            placeholder = ''
                        />
                    </div>

                    <div>
                        <Button 
                            text = "Send Verification"
                        />
                    </div>
                    </form>

                    <p className="mt-2 text-center text-sm text-gray-500">
                    Remember Password?
                    <Link to='/login' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Login</Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword