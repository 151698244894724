import React from "react";
import Logo from '../logo.png';
import { Link } from "react-router-dom";

function LandingPage()
{
    return (
        <>
            <br />
            <br />
            
            <div className="border-[5px] h-[200px] w-[360px] ring-0 mx-auto d-block rounded-[20px]">
                <div className="border-[2px] h-[182px] mt-1 w-[342px] ring-0 mx-auto d-block rounded-[10px]">
                    <img 
                        alt="Hidden Talents Logo"
                        src={Logo} 
                        className="mx-auto d-block"
                    />
                    <div style={{textAlign:'center'}} className=''>
                        <Link to='/login' className="items-center transition-colors text-blue-700 hover:text-blue-500 d-inline ml-3 text-center">
                            Login
                        </Link>
                        <Link to='/register' className="items-center transition-colors text-blue-700 hover:text-blue-500 d-inline ml-3 text-center">
                            Register
                        </Link>
                    </div>
                </div>
            </div>
            <div className="border-[10px] w-[180px] mx-auto d-block"></div>
        </>
    )
}

export default LandingPage