import React from "react";

function Footer()
{
    const footerStyle = {
        marginTop: '-46px',
        fontSize: 'small'
    }
    return (
        <>
            <footer className="text-center" style={footerStyle}>
                Copyright @ Hidden Talents
            </footer>
        </>
    )
}

export default Footer